import { embedDocToObjectRequest, embedDocToViewRequest, exportBoardToSheetRequest, getBoardViewDataRequest, getObjectViewDataRequest } from "../../requests/boardViewRequests";
import Logger from "../../utilities/Logger";

//this function handles embedding spreadsheet to board view
export async function embedSpreadsheetToBoardView(
  userId: string,
  boardId: string,
  workspaceId: number,
  spreadSheetId: string,
  googleToken: string,
  viewId: number
) {
  try {
    //this makes the call to our backend
    const resp = await embedDocToViewRequest(
      userId,
      boardId,
      `${workspaceId}`,
      spreadSheetId,
      googleToken,
      `${viewId}`,
    );
    return resp;
    
  } catch (error) {
    Logger.error(error);
  }
}

//this gets the sheet embedded to a board
export async function getEmbedSpreadsheet(
  userId: string,
  boardId: string,
  viewId: number
) {
  try {
    const response = await getBoardViewDataRequest(
      userId,
      boardId,
      `${viewId}`,
    );

    return response.data;
    
  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

//this function handles embedding spreadsheet to object view
export async function embedSpreadsheetToObjectView(
  userId: string,
  objectId: string,
  workspaceId: number,
  spreadSheetId: string,
  googleToken: string,
) {
  try {
    const resp = await embedDocToObjectRequest(
      userId,
      objectId,
      `${workspaceId}`,
      spreadSheetId,
      googleToken,
    );

    return resp;
    
  } catch (error) {
    Logger.error(error);
  }
}

//this gets the sheet embedded to an object view
export async function getObjectEmbedSpreadsheet(
  userId: string,
  objectId: string,
) {
  try {
    const response = await getObjectViewDataRequest(
      userId,
      objectId,
    );

    return response.data;
    
  } catch (error) {
    Logger.error(error);
    throw error;
  }
}


//this function handles embedding spreadsheet to object view
export async function exportsBoardToSheet(
  userId: string,
  boardId: string,
) {
  try {
    const resp = await exportBoardToSheetRequest(
      userId,
      boardId
    );

    return resp;
    
  } catch (error) {
    Logger.error(error);
    return false
  }
}
