import React from "react";
import {
  Dropdown as MondayDropdown
} from "monday-ui-react-core";
import clsx from "clsx";



type IDropdownOption = {
  label: string;
  value: string | number;
}

type IDropdownProps = {
  className?: string;
  options?: Array<IDropdownOption>;
  placeholder?: string;
  defaultValue?: Array<IDropdownOption>;
  disabled?: boolean;
  searchable?: boolean;
  onChange?: (v: IDropdownOption) => void;
  value?: Array<IDropdownOption>;
}

function Dropdown({
  className,
  placeholder,
  options,
  defaultValue,
  disabled,
  searchable = false,
  value,
  onChange
}: IDropdownProps) {
  return ( 
    <MondayDropdown
      className={clsx(
        // "dropdown-stories-styles_spacing",
        className
      )}
      defaultValue={defaultValue}
      clearable={false}      
      options={options}
      placeholder={placeholder}
      size={MondayDropdown.sizes.SMALL}
      closeMenuOnScroll
      disabled={disabled}
      onChange={onChange}
      onOptionSelect={onChange}
      searchable={searchable}
      value = {value}
      maxMenuHeight={180}
      // color={MondayDropdown.colors.POSITIVE}
    />
  )
}

export default Dropdown