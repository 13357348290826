import { SUPPORTED_OS } from "../resources/constants";
import { BROWSER_OS } from "../resources/enums";

export function getCurrentBrowserOS() {
  if(navigator) {
    if (navigator.userAgent.indexOf(BROWSER_OS.WINDOWS) != -1) return SUPPORTED_OS.Win;
    else if (navigator.userAgent.indexOf(BROWSER_OS.MAC_OS) != -1) return SUPPORTED_OS.Mac;
    else if (navigator.userAgent.indexOf(BROWSER_OS.UNIX) != -1) return SUPPORTED_OS.X11;
    else if (navigator.userAgent.indexOf(BROWSER_OS.LINUX) != -1) return SUPPORTED_OS.Linux;
  }

  return null;
}

export function isArraySubset(arr1: string[], arr2: string[]): boolean {
  // Use a Set for arr2 for efficient lookup
  const arr2Set = new Set(arr2);

  // Check if every element in arr1 is present in arr2Set
  return arr1.every(item => arr2Set.has(item));
}

export function truncatePath(path: string, maxLength: number): string {
  if (path.length <= maxLength) {
    return path;
  }

  const ellipsis = '...';
  const endLength = maxLength - ellipsis.length;
  const truncatedEnd = path.slice(-endLength);

  return `${ellipsis}${truncatedEnd}`;
}