import { useEffect, useState } from "react";
import { GET_ALL_USER_WORKSPACE_QUERY_WITH_LIMIT, GET_USER_WORKSPACES } from "../../resources/queries/board";
import { IWorkspace } from "../../resources/types/responses";
import MondayService from "../../services/MondayService";
import Logger from "../../utilities/Logger";
import _ from "lodash";


const mondayService = new MondayService();

//get all user workspaces
export async function getUserWorkspaces() {
  try {
    const response = await mondayService.query<{ workspaces: IWorkspace[] }>(
      GET_USER_WORKSPACES
    );
    return response.data.workspaces;
    
  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

export async function getUserWorkspacesByPagination(
  limit: number,
  page = 1
) {
  try {
    const response = await mondayService.query<{ workspaces: IWorkspace[] }>(
      GET_ALL_USER_WORKSPACE_QUERY_WITH_LIMIT(limit, page),
    );
    return response.data.workspaces;
    
  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

const LIMIT = 100;
function useWorkspaceByAutoPagination() {
  const [workspaces, setWorkspaces] = useState<Array<IWorkspace>>([]);


  /**
   * This functionality is to add a static main workspace for for old accounts
   * on monday which doesn't return the main workspace as part of their workspaces list
   */
  const addMainWorkspace = (workspaceList: Array<IWorkspace>): Array<IWorkspace> => {
    const found = workspaceList.find((w) => w.name.toLowerCase() == "main workspace");
    
    if(!found) {
      return [...workspaceList, { id: "null", name: "Main Workspace"}]
    }
    return workspaceList;
  }

  const fetchData = async () => {
    try {
      let fetchedWorkspaceLength = 0;
      let page = 1;
      
      do {
        const workspaceList = await getUserWorkspacesByPagination(
          LIMIT,
          page
        );

        fetchedWorkspaceLength = workspaceList.length;
        setWorkspaces((v) => addMainWorkspace([...v, ...workspaceList]));
        page++
  
      } while (fetchedWorkspaceLength == LIMIT)

    } catch (error) {
      Logger.error(error);
      throw error;
    }
  }

  useEffect(() => {
    fetchData();
  }, [])
  


  return {
    workspaces: _.uniqWith(workspaces, _.isEqual)
  }
}

export default useWorkspaceByAutoPagination;