import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { Loader, Text } from "monday-ui-react-core";
import { GoogleSpreadsheetWorksheet } from "google-spreadsheet";
import _, { result } from "lodash";
import { Selection } from "react-spreadsheet";
import {
	ReactGrid,
	CellChange,
	TextCell,
	Id,
	MenuOption,
	CellLocation,
	Column,
	Row,
	DefaultCellTypes,
	SelectionMode,
} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";

import "../../../../styles/sheet.css";
import Logger from "../../../../utilities/Logger";
import { getCurrentBrowserOS } from "../../../../utilities";
import { BROWSER_OS, ClipboardActions } from "../../../../resources/enums";
import {
	DEFAULTS,
	genDefaultColumns,
	genDefaultColumnsData,
	genDummyColumns,
	genDummyRows,
} from "../../../../utilities/sheetUtils";
import Button from "../../../../components/Button";

type Props = {
	sheetData?: GoogleSpreadsheetWorksheet;
	isSyncing?: boolean;
	zoomLevel?: number;
	status?: string;
	isReadonly?: boolean;
};

type CellMetaKeys = "clipboardStructure" | "originalCellPointer";

type CellStatus = "header" | "cell";
type Cell = { value: string; status?: CellStatus; meta?: Partial<Record<CellMetaKeys, unknown>> };
type CellPointer = { row: number; column: number };
type TClipboardItem = {
	pointer: {
		rowId: string;
		columnId: string;
	};
	value: DefaultCellTypes | null;
};

type TClipboardData = {
	action: ClipboardActions;
	items: Array<TClipboardItem>;
};

function Sheet({ sheetData, isSyncing, zoomLevel = 20, status, isReadonly }: Props) {
	const _Sheet = sheetData;
	const sheetRef = useRef<HTMLDivElement>(null);
	const [isLoading, setLoading] = useState(false);
	//the sheetColumns are initialized with default spreadsheet columns
	const [sheetColumns, setSheetColumns] = useState<Array<Column>>(() => genDefaultColumns());
	const [newDataCells, setNewDataCells] = useState<Array<Row>>([]);
	const [clipboard, setClipboard] = useState<TClipboardData[]>([]);
	const [cellChangesIndex, setCellChangesIndex] = React.useState(() => -1);
	//This holds the changes that occurs in cells
	const [cellChanges, setCellChanges] = React.useState<CellChange[][]>(() => []);
	const [addRowNumber, setAddRowNumber] = React.useState<number>(100);
	const [addColumnNumber, setAddColumnNumber] = React.useState<number>(1);
	// const [activeCell, setActiveCell] = useState<[number, number]>(); // Column:Row
	// const [dataCells, setDataCells] = useState<{ value: string}[][]>([]);
	// const [selection, setSelection] = useState<Array<{ row: number; column: number }> | null>(null);
	// const [clipboardData, setClipboardData] = useState<{cells: Cell[], structure?: [number, number]} | null>(null);

	//this handles loading of the sheet headers
	const loadSheetHeader = async () => {
		const columnData: Row = {
			rowId: "row-1",
			cells: [{ type: "header", text: "1", nonEditable: true }],
		};

		if (_Sheet) {
			try {

				try {
					await _Sheet.loadHeaderRow();
				} catch (error) {
					//fill headers with non-breaking space beacuse the package won't work with empty headers
					const empthHeaderSet = new Array(1).fill('_');
					await _Sheet.setHeaderRow(empthHeaderSet); 
					await _Sheet.loadHeaderRow();
				}
				
				const columnCount = _Sheet.gridProperties.columnCount
				const headers = _Sheet.headerValues;
				const remainder = columnCount - headers.length;
				
				let emptyC: TextCell[] = []
				//if the available headers is less than the total number of columns present it the sheet, generate dummy columns to fill them up
				if(_Sheet.headerValues.length < columnCount){
					emptyC = genDummyColumns(
						{
							start: columnCount,
							count: columnCount - _Sheet.headerValues.length,
						},
						isReadonly 
					)
				}
				//if the column count of the sheet is less that the default number of columns, generate dummy columns to make it match the minimum column length	
				const emptyColumns =
					columnCount < DEFAULTS.minimums.column
						? genDummyColumns(
								{
									start: columnCount,
									count: DEFAULTS.minimums.column - columnCount,
								},
								isReadonly
							)
						: [];

				//check for empty headers after last filled header
				emptyColumns.push(...emptyC)
				//push the data into the column data to be displayed on the grid
				columnData.cells.push(
					..._Sheet.headerValues.reduce<Array<DefaultCellTypes>>(
						(cum, cur) => [
							...cum,
							{
								type: "text",
								text: cur,
								nonEditable: isReadonly,
							},
						],
						[]
					),
					...emptyColumns,
				);

				// const columnLength = columnData.cells.length
				// 	? columnData.cells.length < DEFAULTS.minimums.column
				// 		? columnData.cells.length + (DEFAULTS.minimums.column - columnData.cells.length)
				// 		: columnData.cells.length
				// 	: DEFAULTS.minimums.column;

				return {
					data: columnData,
					count: {
						columns: columnData.cells.length,
						rows: 1,
					},
				};
			} catch (error) {
				Logger.error(error);
				columnData.cells.push(...genDummyColumns(undefined, isReadonly));

				return {
					data: columnData,
					length: DEFAULTS,
				};
			}
		}

		return {
			data: undefined,
			length: DEFAULTS,
		};
	};

	//this handles loading of the sheet rows
	const loadSheetRows = async (columns?: Array<Column>) => {
		if (_Sheet) {
			try {
				// Get Rows data
				const rows =
					((await _Sheet.getCellsInRange(
						`A1:${columns?.[columns.length - 1].columnId}${_Sheet.gridProperties.rowCount}`
					)) as string[][]) ?? [];
				
				const results: Row<DefaultCellTypes>[] = [];
				let columnWidth = DEFAULTS.minimums.column;

				let rowIndex = 0;
				const longestRow = _Sheet.gridProperties.columnCount;

				//This is to remove the headers because we need only the rows
				rows.shift()

				//now handle for empty cells after rows return (rows won't return cells that are empty after the last filled column)
				//we have to fill the empty rows with empty values
				// console.log('check', longestRow, rows.length , _Sheet.gridProperties.rowCount)
				if(rows.length < _Sheet.gridProperties.rowCount){
					const remainderCount = _Sheet.gridProperties.rowCount - rows.length
					const emptyRows = new Array(remainderCount).fill(['']);
					rows.push(...emptyRows)
				}


				for (const row of rows) {
					// Sets the highest columns
					if (row.length > columnWidth) columnWidth = row.length;

					//generate empty cells for rows that are not upto the longest row
					const emptyColumns =
					longestRow > row.length
							? genDummyColumns({
									start: row.length,
									count: longestRow - row.length,
									
								})
							: [];

					const data: Row<DefaultCellTypes> = {
						rowId: `row-${rowIndex + 2}`,
						cells: [
							{ type: "header", text: `${rowIndex + 2}`, nonEditable: isReadonly },
							..._.map(row, (cell: any) => {
								return {
									type: "text",
									text: cell || "",
									nonEditable: isReadonly,
								} as DefaultCellTypes;
							}),
							...emptyColumns,
						],
						reorderable: false,
					};
					results.push(data);

					rowIndex++;
				}

				if (results.length < _Sheet.gridProperties.rowCount) {
					const remainder = _Sheet.gridProperties.rowCount - results.length;
					const emptyRows = genDummyRows(
						{ start: results.length + 2, count: remainder - 1 },
						undefined,
						isReadonly
					);

					results.push(...emptyRows);
				}

				// console.log('results >>', results)


				const rowLength = results.length
					? results.length < DEFAULTS.minimums.rows
						? rows.length + (DEFAULTS.minimums.rows - rows.length)
						: rows.length
					: DEFAULTS.minimums.rows;
				results.pop()
				return {
					data: results,
					length: rowLength - 1,
				};
			} catch (error) {
				Logger.error(error);

				return {
					data: genDummyRows({ start: 1 }, undefined, isReadonly),
					length: 250,
				};
			}
		}
		return {
			data: genDummyRows(undefined, undefined, isReadonly),
			length: 250,
		};
	};

	//this handles populating data from source. Getting the sheet data from the googlesheet in the drive
	const populateDataFromSource = async () => {
		try {
			if (_Sheet) {
				// Get Columns
				const columnData = await loadSheetHeader();

				// Get Rows
				// Excel default columns
				const rowHeaders = genDefaultColumns(
					columnData.count ? columnData.count.columns - 1 : undefined
				);

				const rowsData = await loadSheetRows(rowHeaders);
				setSheetColumns(rowHeaders);

				// Cumulated data
				const cumRowData: Row<DefaultCellTypes>[] = [genDefaultColumnsData(rowHeaders)];

				// populate headings
				if (columnData.data) {
					cumRowData.push(columnData.data);
				}

				// Populate rows
				if (rowsData?.data) {
					cumRowData.push(...rowsData.data);
				}

				setNewDataCells(cumRowData);
			}
		} catch (error) {
			Logger.error(error);
		}
	};

	//this handles getting the selected sheet data 
	const loadSheetData = async () => {
		try {
			setLoading(true);

			if (_Sheet) {
				await populateDataFromSource();
			}
		} catch (error) {
			Logger.error(error);
		} finally {
			setLoading(false);
		}
	};


	const resetView = () => {
		setNewDataCells([]);
		// setActiveCell(undefined);
		setLoading(false);
	};

	//this handles syncing changes made out of monday context into the sheet embedded in the boardview or object view
	const syncData = async () => {
		try {
			if (_Sheet) {
				const syncFunc = async () => {
					await _Sheet.saveUpdatedCells();
				};
				//this should run after a minute because of rate limiting
				const debouncedSync = _.debounce(syncFunc, 60000);

				debouncedSync();
			}
		} catch (error) {
			Logger.error(error);
		}
	};


	const rebaseWithSource = async () => {
		try {
			if (_Sheet) {
				await _Sheet.saveUpdatedCells();
			}
			await populateDataFromSource();
		} catch (error) {
			Logger.error(error);
		}
	};

	const getCellIndexById = (rowId: string, columnId: string) => {
		const indexOfRow = newDataCells.findIndex((cell) => cell.rowId == rowId);
		const indexOfColumn = newDataCells[0].cells.findIndex((c) => (c as TextCell).text == columnId);

		return {
			rowIndex: indexOfRow,
			columnIndex: indexOfColumn,
		};
	};

	const getCellValue = (rowId: string, columnId: string) => {
		const { columnIndex, rowIndex } = getCellIndexById(rowId, columnId);

		if (rowIndex && columnIndex) {
			const row = newDataCells[rowIndex];
			return row.cells[columnIndex];
		}

		return null;
	};

	const setCellValueByIndex = (rowIndex: number, columnIndex: number, value = "") => {
		if (rowIndex && columnIndex) {
			newDataCells[rowIndex].cells[columnIndex] = {
				...newDataCells[rowIndex].cells[columnIndex],
				text: value,
			} as TextCell;

			setNewDataCells([...newDataCells]);

			if (_Sheet) {
				/**
				 * -1 removes the index for sheets header count(A, B, C ...)
				 * and removes tow count (1, 2, 3, 4, ...)
				 *  */
				_Sheet.getCell(rowIndex - 1, columnIndex - 1).value = value;
				// syncData();
			}
		}
	};

	const setCellValueById = (rowId: string, columnId: string, value = "") => {
		const { rowIndex, columnIndex } = getCellIndexById(rowId, columnId);
		// console.log(rowIndex, columnIndex, value)
		setCellValueByIndex(rowIndex, columnIndex, value);
	};

	const clearCellValue = (rowId: string, columnId: string) => {
		setCellValueById(rowId, columnId);
	};

	const handleColumnResize = (columnId: Id, width: number) => {
		setSheetColumns((prevColumns) => {
			const columnIndex = prevColumns.findIndex((el) => el.columnId === columnId);
			const resizedColumn = prevColumns[columnIndex];
			const updatedColumn = { ...resizedColumn, width };
			prevColumns[columnIndex] = updatedColumn;
			return [...prevColumns];
		});
	};

	// const predictSelectionStructure = (selection: Array<CellPointer>): [number, number] | undefined => {
	//   if(selection && selection.length) {
	//     let prevRow = selection[0].row;
	//     let rowsCounter = 1;
	//     let columnsCounter = 0;

	//     for(const pointer of selection) {
	//       if(pointer.row > prevRow) {
	//         rowsCounter += 1;
	//       }

	//       if(pointer.column > columnsCounter) {
	//         columnsCounter = pointer.column
	//       }

	//       prevRow = pointer.row;
	//     }

	//     return [rowsCounter, columnsCounter + 1]
	//   }
	// }

	// handles copying data into context/clipboard (we handle this manually because monday disabled reading clipboard from iframe)
	const handleCopy = (range: CellLocation[]) => {
		const data = range.reduce<Array<TClipboardItem>>(
			(cum, cur) => [
				...cum,
				{
					pointer: {
						rowId: cur.rowId as string,
						columnId: cur.columnId as string,
					},
					value: getCellValue(cur.rowId as string, cur.columnId as string),
				},
			],
			[]
		);

		return data;
	};

	const handleClear = (range: CellLocation[]) => {
		range.forEach((cur) => {
			clearCellValue(cur.rowId as string, cur.columnId as string);
		});
	};

	// const handleCut = () => {
	//   if (selection) {
	//     const cutData = selection.map<Cell>(
	//       ({ row, column }) => ({
	//         ...dataCells[row][column],
	//         meta: {
	//           originalCellPointer: [row, column]
	//         }
	//       })
	//     );
	//     setClipboardData({
	//       cells: cutData,
	//       structure: predictSelectionStructure(selection)
	//     });

	//     const newData = dataCells.map((row, rowIndex) =>
	//       row.map((cell, columnIndex) => {
	//         const isCellSelected = selection.some(
	//           (sel) => sel.row === rowIndex && sel.column === columnIndex
	//         );
	//         return isCellSelected ? { value: "" } : cell;
	//       })
	//     );
	//     setDataCells([...newData]);
	//   }
	// }

	//handles pasting copied items 
	const handlePaste = (range: CellLocation[][]) => {
		if (range.length > 0) {
			const clipboardData = clipboard[clipboard.length - 1];
			const grpClipboardData = Object.entries(_.groupBy(clipboardData?.items, "pointer.rowId"));
			const activeCell = range[0][0];
			const activeCellIndexes = getCellIndexById(
				activeCell.rowId as string,
				activeCell.columnId as string
			);

			for (let rowIndex = 0; rowIndex < grpClipboardData.length; rowIndex++) {
				const rowData = grpClipboardData[rowIndex][1];
				for (let columnIndex = 0; columnIndex < rowData.length; columnIndex++) {
					setCellValueByIndex(
						rowIndex + activeCellIndexes.rowIndex,
						columnIndex + activeCellIndexes.columnIndex,
						(rowData[columnIndex].value as TextCell)?.text
					);
					// const upd = {
					//   ...currentCell,
					//   ...rowData[columnIndex].value as TextCell,
					// }
					// setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), upd]);
					// setCellChangesIndex(cellChangesIndex + 1)
					// let currentCell = newDataCells[rowIndex + activeCellIndexes.rowIndex]
					// .cells[columnIndex + activeCellIndexes.columnIndex] as TextCell;

					// currentCell = {
					//   ...currentCell,
					//   ...rowData[columnIndex].value as TextCell,
					// }

					// newDataCells[rowIndex + activeCellIndexes.rowIndex]
					// .cells[columnIndex + activeCellIndexes.columnIndex] = currentCell;
					// setNewDataCells([...newDataCells]);
				}
			}
		}
	};

	const handleUndoChanges = () => {
		if (cellChangesIndex >= 0) {
			updateCellFromChange(cellChanges[cellChangesIndex], true);
			setCellChangesIndex(cellChangesIndex - 1);
		}
	};

	const handleRedoChanges = () => {
		if (cellChangesIndex + 1 <= cellChanges.length - 1) {
			updateCellFromChange(cellChanges[cellChangesIndex]);
			setCellChangesIndex(cellChangesIndex + 1);
		}
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		const OS = getCurrentBrowserOS();

		if (OS?.id == BROWSER_OS.MAC_OS ? event.metaKey : event.ctrlKey) {
			// if (event.key === "c") { // Copy
			//   handleCopy();
			// }

			// if (event.key === 'x') { // Cut
			//   handleCut();
			// }

			// if (event.key === "v") { // Paste
			//   handlePaste();
			// }

			if (event.key === "z") {
				// Paste
				handleUndoChanges();
			}

			if (event.key === "y") {
				// Paste
				handleRedoChanges();
			}
		}

		// if(event.key === "Backspace" || event.key === "Deleted") {
		//   handleDelete();
		// }
	};

	const updateCellFromChange = (changes: CellChange[], usePrevValue = false) => {
		changes?.forEach((change) => {
			const newValue = usePrevValue
				? (change.previousCell as TextCell).text
				: (change.newCell as TextCell).text;
			const rowId = change.rowId;
			const columnId = change.columnId;
			setCellValueById(rowId as string, columnId as string, newValue);
		});
	};

	const handleChanges = (changes: CellChange[]) => {
		updateCellFromChange(changes);
		setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes]);
		setCellChangesIndex(cellChangesIndex + 1);
	};

	//this handles adding new rows to the sheet
	const addNewRows = async () => {
		if (_Sheet) {
			setLoading(true);
			const lastRowIndex = _Sheet.rowCount;
			const existingRows = newDataCells;
			const emptyRows = new Array(addRowNumber).fill(['']);
			const newRowsData: any[] = [];

			// Add rows before the target row
			//start from 1 to skip headers
			for (let i = 1; i <= lastRowIndex; i++) {
				const data = []
				for (let j = 1; j < existingRows[i]?.cells?.length; j++) {
					//@ts-ignore
					const cell = existingRows[i]?.cells[j]?.text
					data.push(cell)
					
				}
				newRowsData.push(data);
			}

			// Add each empty rows to the new rows
			emptyRows.forEach((row) => newRowsData.push(row));

			const headers = _Sheet.headerValues;

			// Clear the entire _Sheet
			await _Sheet.clear();

			// Re-add the header row
			await _Sheet.setHeaderRow(headers);
			newRowsData.shift()
			await _Sheet.addRows(newRowsData);

			await _Sheet.loadCells();
			loadSheetData();
		}
	};

	//this handles adding new columns
	const addNewColumns = async () => {
		if (_Sheet) {
			setLoading(true);
			const headers = _Sheet.headerValues;
			const remainder = newDataCells[0].cells.length - headers.length;
			const emptyColumns = new Array(addColumnNumber + remainder).fill('');
			
			// Add the header row and empty columns created
			const newHeaders = [...headers, ...emptyColumns]
			await _Sheet.resize({ columnCount: newHeaders.length, rowCount: _Sheet.rowCount });
			await _Sheet.setHeaderRow(newHeaders);

			await _Sheet.loadCells();
			loadSheetData();
		}
	};

	const contextMenu = (
		selectedRowIds: Id[],
		selectedColIds: Id[],
		selectionMode: SelectionMode,
		menuOptions: MenuOption[],
		selectedRanges: CellLocation[][]
	): MenuOption[] => {
		const contextMenuOptions: MenuOption[] = [];
		if (!isReadonly) {
			if (selectionMode == "row") {
				contextMenuOptions.push(
					...[
						{
							id: "copy-row",
							label: "Copy row",
							handler: () => {
								if (selectedRanges.length > 0) {
									const copiedData = handleCopy(selectedRanges[0]);

									setClipboard([
										...clipboard,
										{
											action: ClipboardActions.copy,
											items: copiedData,
										},
									]);
								}
							},
						},
						{
							id: "cut-row",
							label: "Cut row",
							handler: () => {
								if (selectedRanges.length > 0) {
									const copiedData = handleCopy(selectedRanges[0]);
									handleClear(selectedRanges[0]);

									setClipboard([
										...clipboard,
										{
											action: ClipboardActions.cut,
											items: copiedData,
										},
									]);
								}
							},
						},
						{
							id: "paste",
							label: "Paste",
							handler: () => {
								if (selectedRanges.length > 0) {
									handlePaste(selectedRanges);
								}
							},
						},
						{
							id: "clear-row",
							label: "Clear Row",
							handler: () => {
								if (selectedRanges.length > 0) {
									handleClear(selectedRanges[0]);
								}
							},
						},
					]
				);
			} else if (selectionMode == "column") {
				contextMenuOptions.push(
					...[
						{
							id: "copy-column",
							label: "Copy Column",
							handler: () => {
								if (selectedRanges.length > 0) {
									const copiedData = handleCopy(selectedRanges[0]);

									setClipboard([
										...clipboard,
										{
											action: ClipboardActions.copy,
											items: copiedData,
										},
									]);
								}
							},
						},
						{
							id: "cut-column",
							label: "Cut column",
							handler: () => {
								if (selectedRanges.length > 0) {
									const copiedData = handleCopy(selectedRanges[0]);
									handleClear(selectedRanges[0]);

									setClipboard([
										...clipboard,
										{
											action: ClipboardActions.cut,
											items: copiedData,
										},
									]);
								}
							},
						},
						{
							id: "paste",
							label: "Paste",
							handler: () => {
								if (selectedRanges.length > 0) {
									handlePaste(selectedRanges);
								}
							},
						},
						{
							id: "clear-column",
							label: "Clear Column",
							handler: () => {
								if (selectedRanges.length > 0) {
									handleClear(selectedRanges[0]);
								}
							},
						},
						{
							id: "add-new-column",
							label: "Add Column at the end",
							handler: () => {
								setAddColumnNumber(()=> 1)
								addNewColumns()
							},
						},
					]
				);
			} else if (selectionMode == "range") {
				const hasSelectedCells = selectedRanges.length > 1;

				contextMenuOptions.push(
					...[
						{
							id: hasSelectedCells ? "copy-cells" : "copy-cell",
							label: "Copy",
							handler: () => {
								if (selectedRanges.length > 0) {
									const copiedData = handleCopy(selectedRanges[0]);

									setClipboard([
										...clipboard,
										{
											action: ClipboardActions.copy,
											items: copiedData,
										},
									]);
								}
							},
						},
						{
							id: hasSelectedCells ? "cut-cells" : "cut-cell",
							label: "Cut",
							handler: () => {
								if (selectedRanges.length > 0) {
									const copiedData = handleCopy(selectedRanges[0]);
									handleClear(selectedRanges[0]);

									setClipboard([
										...clipboard,
										{
											action: ClipboardActions.cut,
											items: copiedData,
										},
									]);
								}
							},
						},
						{
							id: "paste",
							label: "Paste",
							handler: () => {
								if (selectedRanges.length > 0) {
									handlePaste(selectedRanges);
								}
							},
						},
						{
							id: "clear",
							label: "Clear",
							handler: () => {
								if (selectedRanges.length > 0) {
									handleClear(selectedRanges[0]);
								}
							},
						},
					]
				);
			}
		}

		return contextMenuOptions;
	};

	useEffect(() => {
		loadSheetData();
	}, [isReadonly]);

	// Periodic synchronization
	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;

		if (sheetData) {
			loadSheetData();

			// Set Periodic sync
			if (interval) {
				clearInterval(interval);
			}

			interval = setInterval(() => {
				syncData();
			}, 1000 * 60);
		} else {
			resetView();
			if (interval) {
				clearInterval(interval);
			}
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [sheetData]);

	// Synchronize data with source
	useEffect(() => {
		if (isSyncing) {
			rebaseWithSource();
		}
	}, [isSyncing]);

	// Column resizing base on zoom levels
	useEffect(() => {
		let columnSize = 100;
		let rowSize = 24;

		switch (zoomLevel) {
			case 50:
				columnSize = 48;
				rowSize = 12;
				break;
			case 75:
				columnSize = 64;
				rowSize = 18;
				break;
			case 90:
				columnSize = 80;
				rowSize = 22;
				break;
			case 125:
				columnSize = 120;
				rowSize = 30;
				break;
			case 150:
				columnSize = 135;
				rowSize = 32;
				break;
			case 200:
				columnSize = 150;
				rowSize = 34;
				break;

			case 100:
			default:
				columnSize = 100;
				rowSize = 24;
				break;
		}

		const columns = sheetColumns.map((c, index) => {
			if (index == 0) return c;

			return {
				...c,
				width: columnSize,
			};
		});
		const rows = newDataCells.map((r) => ({
			...r,
			height: rowSize,
		}));
		setSheetColumns([...columns]);
		setNewDataCells([...rows]);
	}, [zoomLevel]);

	return (
		<div className="w-full h-full">
			{/* Sheet Loader */}
			{isLoading && (
				<div className="w-full h-full absolute bg-gray-50/10 backdrop-blur-sm z-10">
					<div className="translate-x-[calc(100vw_/_2)] translate-y-[calc((100vh_/_2)_-_178px)] w-fit">
						<Loader size={Loader.sizes.MEDIUM} className="text-primary" />
					</div>
				</div>
			)}

			{/* Sheet Status */}
			{status && (
				<div className="fixed bottom-[108px] right-6 w-fit bg-transparent z-10">
					<Text className="text-primary animate-pulse">{status}</Text>
				</div>
			)}

			{/* Sheet */}
			<div className={`gsfi-font-size-${zoomLevel}`} onKeyDown={handleKeyDown}>
				<ReactGrid
					columns={sheetColumns}
					rows={newDataCells}
					onCellsChanged={handleChanges}
					stickyTopRows={1}
					stickyLeftColumns={1}
					enableColumnSelection
					enableRowSelection
					enableRangeSelection
					enableFillHandle
					onContextMenu={contextMenu}
					onColumnResized={handleColumnResize}
				/>
				<div id="add-new-rows" className="py-3 px-2 flex gap-3 items-center">
					<Button onClick={addNewRows}>Add</Button>
					<input
						type="number"
						className="cursor-pointer px-2 w-24 text-grayed border relative group flex justify-between items-center"
						value={addRowNumber}
						onChange={(e) => setAddRowNumber(Number(e.target.value))}
						min={1}
					/>
					<p>more rows at the bottom</p>
				</div>
				<div id="add-new-columns" className="py-3 px-2 flex gap-3 items-center">
					<Button onClick={addNewColumns}>Add</Button>
					<input
						type="number"
						className="cursor-pointer px-2 w-24 text-grayed border relative group flex justify-between items-center"
						value={addColumnNumber}
						onChange={(e) => setAddColumnNumber(Number(e.target.value))}
						min={1}
					/>
					<p>more columns at the end</p>
				</div>
			</div>
		</div>
	);
}

export default React.memo(Sheet);
