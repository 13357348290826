import React, { useState } from "react";
import _ from "lodash";
import { IoWarning } from "react-icons/io5";
import { Text } from "monday-ui-react-core";

import { CircledInfoIcon, DownloadIcon } from "../../../resources/icons";
import Button from "../../../components/Button";
import {
	useWorkspaceBoardsByAutoPagination,
} from "../../../hooks/monday/boardhooks";
import Logger from "../../../utilities/Logger";
import { IDropdownOption } from "../../../resources/types/responses";
import Dropdown from "../../../components/Dropdown";
import useWorkspaceByAutoPagination from "../../../hooks/monday/workspaceHooks";
import { exportBoardToSheetRequest } from "../../../requests/boardViewRequests";
import useUser from "../../../hooks/states/userHook";
import { getAllBoardItemsAndHeaders } from "../../../hooks/monday/boardItemsHooks";


interface IBoardSelectionProps {
	isSheetActive?: boolean;
	onCancel?: () => void;
	onImport: (headers: string[], rows: (string | number | boolean | Date)[][]) => Promise<void>;
	onLimitExceeded?: () => void;
}

function BoardSelection({ isSheetActive, onCancel, onImport, onLimitExceeded }: IBoardSelectionProps) {
	const { workspaces } = useWorkspaceByAutoPagination();
	const WorkSpaceOptions: IDropdownOption[] = workspaces.map((workspace) => ({
		label: workspace.name,
		value: workspace.id,
	}));
	const { 
		userId, 
	} = useUser();
	const [workspaceID, setWorkspaceID] = useState<number | string | null>(0);
	const { boardOptions } = useWorkspaceBoardsByAutoPagination(workspaceID, true);
	const [selectedBoard, setSelectedBoard] = useState("");
	const [isSubmitting, setSubmitting] = useState(false);



	const handleCloseModal = () =>{
		setSelectedBoard("");
		setWorkspaceID(0);
		onCancel ? onCancel(): null;
	}

	//this handles embedding selected board to the imported sheet
	const handleEmbedBoardToSheet = async () => {
		try {
			setSubmitting(true);

			if (selectedBoard) {
				const canExport = await exportBoardToSheetRequest(userId, selectedBoard)

				if(canExport) {
					const boardData = await getAllBoardItemsAndHeaders(selectedBoard);

					console.log(boardData)
	
					if (boardData.items.length) {
	
						await onImport(
							boardData.headers,
							boardData.items
						);
						onCancel && onCancel();
					}
				} else {
					handleCloseModal();
					onLimitExceeded && onLimitExceeded();
				}
			}

		} catch (error) {
			Logger.error(error);
			
		} finally {
			setSubmitting(false);
		}
	};


	return (
		<div className="w-full px-6 space-y-4">
			<div className="space-y-4">
				<Text className="text-grayed font-normal text-2xl leading-[22px] inline-flex items-center gap-2">
					Select board to export
					<DownloadIcon />
				</Text>

				<Text className="text-grayed text-wrap">
					Please select the board to export to Google Sheet and load into the embed view
				</Text>
			</div>

			<div className="space-y-3 h-64">
				{/* Workspace dropdown */}
				<div className="relative w-full">
					<p>Workspace</p>
					<Dropdown
						placeholder="Select workspace"
						options={WorkSpaceOptions}
						searchable={true}
						value={WorkSpaceOptions.filter(e => e?.value == workspaceID)}
						className="min-w-[88px] z-12"
						onChange={(option) => {
							setWorkspaceID(option.value);
							setSelectedBoard("");
						}}
					/>
					
					{/* Boards dropdown */}
					<p className="mt-3">Board</p>
					<div className="h-10">
						<Dropdown
							placeholder={workspaceID? "Select Board" : "Select a workspace first"}
							options={boardOptions}
							searchable={true}
							value={boardOptions.filter(e => e?.value == selectedBoard)}
							className="min-w-[88px] z-12 mb-3"
							onChange={(option) => {
								setSelectedBoard(option.value.toString());
							}}
						/>
					</div>
					
				</div>

				
				<div className="flex items-start gap-3 my-3">
					{isSheetActive ? (
						<>
							<CircledInfoIcon className="text-grayed h-8 w-8" />
							<Text className="text-grayed text-xs text-wrap mb-2">
								Exporting this board will share its data with a Google Sheet and display it in the
								embedded view, allowing seamless interaction with the data. <br />
								For workspaces with +1000 boards, please wait at least 30 seconds to load.
							</Text>
						</>
					) : (
						<>
							<IoWarning className="h-8 w-8 text-red-500" />
							<Text className="text-xs text-wrap text-red-500">
								A sheet should be embedded or created to use this feature.
								<br />
								Feature requires an active sheet in preview
							</Text>
						</>
					)}
				</div>
			</div>

			{/* Footer */}
			<footer className="mt-5" style={{ marginTop: "50px" }}>
				<div className="w-full flex gap-x-4 items-center justify-between">
					<Button variant="outlined" onClick={handleCloseModal}>
						Cancel
					</Button>
					
					<Button
						loading={isSubmitting}
						disabled={isSubmitting || !isSheetActive}
						onClick={handleEmbedBoardToSheet}
					>
						Export to Sheet
					</Button>
				</div>
			</footer>
		</div>
	);
}

export default BoardSelection;
