import { useSelector } from "react-redux";

import { getUser } from "../../states/slices/userSlice";



function useUser() {
  const { 
    userId, 
    accountId, 
    google, 
    boardId, 
    viewId, 
    workspaceId,
    appFeature
  } = useSelector(getUser);
  const isAuthorized = !!userId && !!accountId;
  const isGoogleConnected = !!google && !!google.token
  const GoogleDetails = {
    ...google,
    initials: google?.name.firstName.toUpperCase() || "" + google?.name.lastName.toUpperCase() || ""
  };
  const driveToken = google?.token;

  
  return {
    userId,
    accountId,
    boardId,
    viewId,
    workspaceId,
    GoogleDetails,
    driveToken,
    isAuthorized,
    appFeature,
    isGoogleConnected
  }
}

export default useUser;
