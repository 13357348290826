import { GET_BOARD_ITEMS_QUERY, GET_BOARD_ITEMS_QUERY_BY_LIMIT } from "../../resources/queries/board";
import { IBoard, } from "../../resources/types/responses";
import MondayService from "../../services/MondayService";
import Logger from "../../utilities/Logger";



const mondayService = new MondayService();

export async function getUserBoardItems(boardId: string) {
  try {
    const response = await mondayService.query<{ boards: IBoard[] }>(
      GET_BOARD_ITEMS_QUERY(boardId)
    );
    
    return response.data.boards;

  } catch (error) {
    Logger.error(error);
    throw error;
  }
}

//get all items from board
export async function getAllBoardItemsAndHeaders(boardId: string) {
  try {
    const items: string[][] = [];
    let cursor = null;
    let headers: string[] = [];
    
    do {
      const response = (await mondayService.query<{ boards: IBoard[] }>(
        GET_BOARD_ITEMS_QUERY_BY_LIMIT(
          boardId,
          500,
          cursor
        )
      )) as {
        data: {
          boards: IBoard[];
        };
        account_id: number;
      };

      const foundBoard = response.data.boards[0];
      // Filters out the subitems column's header
      if(headers.length < 1) {
        headers = (foundBoard.columns ?? [])
          .filter(c => !(c.id == "subitems" || c.id.includes("subitems")))
          .map((c) => c.title);
      }

      // Filters out the subitems column's values
      const rows = foundBoard.items_page.items.map((item) => [
        item.name,
        ...(item.column_values
          .filter(c => !(c.id == "subitems" || c.id.includes("subitems")))
          .map((cv) => cv.text ?? ""))
      ]);

      items.push(...rows);
      cursor = response.data.boards[0].items_page?.cursor;
    } while (cursor);

    return {
      items,
      headers
    };
  } catch (error) {
    Logger.error("Error in getAllItems function:", error);
    throw error;
  }
}