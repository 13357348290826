import React from 'react';
import useCrisp from '../hooks/components/crispHook';
import Button from '../components/Button';

interface HowToCardProps {
  title: string;
  description: string;
  videoUrl: string;
}

const HowToCard: React.FC<HowToCardProps> = ({ title, description, videoUrl }) => {
  return (
    <div className="w-full max-w-2xl mx-auto rounded overflow-hidden shadow-lg my-8 bg-white">
      <div className="relative w-full h-64">
        <iframe
          className="w-full h-full"
          src={videoUrl}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      {/* <div className="px-6 py-4">
        <div className="font-bold text-2xl mb-2">{title}</div>
        <p className="text-gray-700 text-base" dangerouslySetInnerHTML={{__html: description}}>
        </p>
      </div> */}
    </div>
  );
};

const OnboardingPage: React.FC = () => {
  useCrisp()
  const howToCards = [
    {
      title: "Attach Google Sheets",
      description: 'Connect your Google Sheets right into a board view or set them up as a new board in the left pane. Your specific sheet loads straight into monday, keeping everything neat and accessible. And guess what? Any edits you make within monday get auto-synced to Google Sheets every minute or instantly with a manual refresh.',
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ', // Replace with your actual video URL
    },
    // {
    //   title: 'Export Board Data to Google Sheets',
    //   description: 'Seamlessly export your monday.com board data to Google Sheets with a simple automation or the click of a button.',
    //   videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ', // Replace with your actual video URL
    // },
    // {
    //   title: 'Custom Automations',
    //   description: 'Want it your way? Craft personalized automations to ensure your data stays synced between monday and Google Sheets. Set up custom triggers to export data, create new rows or tasks, let the app do the heavy lifting for you.',
    //   videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ', // Replace with your actual video URL
    // },
    // {
    //   title: 'Two-Way Sync (coming soon)',
    //   description: 'Experience true two-way sync with real-time data refresh, ensuring your data is always accurate and up-to-date across both platforms.',
    //   videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ', // Replace with your actual video URL
    // },
    // {
    //   title: 'Don’t just imagine the possibilities—EXPERIENCE them.',
    //   description: 'Install our Google Sheets Integration and enjoy the benefits NOW. <br/> Need Guidance? Not sure where to start? Book an onboarding <a class="text-blue-600" href="https://tidycal.com/lupapps">call with us</a>',
    //   videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ', // Replace with your actual video URL
    // },
  ];

  const bookCall = () => {
    const newTab = window.open("https://tidycal.com/lupapps", '_blank');
  }

  return (
    <div style={{background: "#cbeec5", minWidth: '100vw', minHeight: '100vh'}}>
      <div className="container max-w-[850px] mx-auto px-4 pb-10">
        <div className="text-center py-8">
          <h1 className="text-4xl font-bold text-center mb-6">Google Sheets Integration for monday.com</h1>
          <h3 className="text-3xl">Welcome aboard!</h3>
        </div>

        <div className="space-y-4 text-center">
          <p>
            We&apos;re excited to have you! To help you get started, we&apos;ve prepared a quick onboarding guide 
            that highlights the powerful features you can unlock with google sheets integration.
          </p>

          {/* <p>
            If you have any questions feel free to reach out through the support bubble on the bottom right 
            corner–we&apos;re here to help!
          </p> */}
        </div>

        {/* Video */}
        <div className="flex flex-col items-center">
          {howToCards.map((card, index) => (
            <HowToCard
              key={index}
              title={card.title}
              description={card.description}
              videoUrl={card.videoUrl}
            />
          ))}
        </div>

        {/* Content */}
        <div className="mb-10">
          {/* Heading */}
          <div className="text-center py-8 font-bold">
            <h3 className="text-3xl">Set It and Forget It! 🚀</h3>
            <h4>Quick Onboarding Guide</h4>
          </div>

          <div className="space-y-16">
            {/* Connect Google Drive */}
            <div>
              <h3 className="text-2xl font-bold mb-8">Connect Google Drive</h3>
              
              <p>
                Bring your Google Sheets into any monday.com board, dashboard, or view. 
                Just follow these simple steps:
              </p>

              <ul className="list-none list-inside mb-5">
                <li className="list-item">
                  <span className="font-bold">Step 1:</span> Click “Authorize” to enable the Google Sheets integration.
                </li>
                <li className="list-item">
                  <span className="font-bold">Step 2:</span> Connect your Google Drive for an instant sheets sync.
                </li>
                <li className="list-item">
                  <span className="font-bold">Step 3:</span> Choose where you want to add the app: item view, board view, 
                  new board, dashboard,  or product view.
                </li>
                <li>
                  <span className="font-bold">Step 4:</span> Click the “Load New Sheet” button, then select the Google Sheet 
                  you want to link from your Drive.
                </li>
                <li className="list-item">
                  <span className="font-bold">Step 5:</span> Once connected, your sheet stays integrated, and any changes made in 
                  monday.com will automatically sync with your Google Sheet—instantly or with a manual refresh.
                </li>
              </ul>

              <h3 className="text-center font-bold text-lg">
                Say goodbye to manual updates and enjoy a fully synced workspace!
              </h3>
            </div>

            {/* Board Data Exports in One Click */}
            <div>
              <h3 className="text-2xl font-bold mb-8">
                Board Data Exports in One Click
              </h3>
              
              <p>Transfer your board data from monday.com to Google Sheets in seconds:</p>

              <ul className="list-none list-inside mb-5">
                <li className="list-item">
                  <span className="font-bold">Step 1:</span> Make sure you&apos;ve loaded a Google Sheet in your 
                  embedded view.
                </li>
                <li className="list-item">
                  <span className="font-bold">Step 2:</span> Click the “Board to Sheet” button.
                </li>
                <li className="list-item">
                  <span className="font-bold">Step 3:</span> Select the workspace and board you want to export.
                </li>
                <li>
                  <span className="font-bold">Step 4:</span> Hit “Export to Sheet”—your board data will instantly appear 
                  in the embedded sheet and sync automatically with your Google Sheets.
                </li>
              </ul>

              <h3 className="text-center font-bold text-lg">
                Keep your reports up-to-date without lifting a finger, ensuring smooth data management at all times.
              </h3>
            </div>

            {/* Automate Workflows for Maximum Efficiency */}
            <div>
              <h3 className="text-2xl font-bold mb-8">
                Automate Workflows for Maximum Efficiency
              </h3>
              
              <p>Let automation handle the heavy lifting for you with two easy setup options:</p>

              <div className="space-y-5 mb-8">
                <div>
                  <h4 className="text-lg font-semibold">1. Pre-Built Automation Recipes</h4>
                  <p>
                    We&apos;ve crafted the most common automations so you don&apos;t have to! Here&apos;s 
                    how to use our pre-made recipes:
                  </p>
                  <ul className="list-none list-inside mb-5">
                    <li className="list-item">
                      <span className="font-bold">Step 1:</span> Click the “Integrate” button in the top-right corner.
                    </li>
                    <li className="list-item">
                      <span className="font-bold">Step 2:</span> Search for “Google Sheets Full Integration” and click 
                      the app&apos;s logo.
                    </li>
                    <li className="list-item">
                      <span className="font-bold">Step 3:</span> Choose from dozens of pre-built recipes, designed to 
                      meet the most common automation needs.
                    </li>
                  </ul>
                  
                  <p>If you have an idea for a new recipe, chat with us—we might be able to implement it in seconds!</p>
                </div>

                <div>
                  <h4 className="text-lg font-semibold">2. Custom Automations</h4>
                  <p>Create your own automation, perfectly tailored to your needs:</p>

                  <ul className="list-none list-inside mb-5">
                    <li className="list-item">
                      <span className="font-bold">Step 1:</span> Click the “Automate” button (top-right corner), then 
                      select “Add New Automation.”
                    </li>
                    <li className="list-item">
                      <span className="font-bold">Step 2:</span> Choose your preferred trigger (e.g., when an item is created).
                    </li>
                    <li className="list-item">
                      <span className="font-bold">Step 3:</span> Select the automation operation “Export to Google Sheet”, then 
                      choose the Google Sheet you&apos;d like to export to.
                    </li>
                    <li className="list-item">
                      <span className="font-bold">Step 4:</span> Type in the specific sheet name (Tab) you want to export to. If 
                      the sheet doesn&apos;t exist, we&apos;ll create a new one for you with the same name.
                    </li>
                  </ul>
                  
                  <p>
                    Want to implement a different automation but can&apos;t find it? Chat with us! We&apos;re here to help and might 
                    be able to set it up for you in no time.
                  </p>
                </div>
              </div>


              <h3 className="text-center font-bold text-lg">
                Automations mean less manual work and more time to focus on what really matters.
              </h3>
            </div>

            {/* Automation Note */}
            <div className="text-center">
              <h3 className="text-2xl font-bold">
                Automation Note
              </h3>

              <p>
                Automation recipes can only be set up through the board view, as they require board data 
                for proper functionality. Other views don&apos;t have this direct connection to board data.
              </p>
            </div>

            {/* Contact Us */}
            <div className="text-center">
              <h3 className="text-2xl font-bold">Contact Us</h3>

              <p> Need more help or have any questions? </p>
              <p className="mb-3">
                We&apos;re here for you! Feel free to reach out through the support bubble or book a 
                personalized onboarding call.
              </p>
              <Button onClick={bookCall}>Book a call</Button>
            </div>

          </div>
        </div>
        
        <div className="space-y-5 text-center">
          <h3 className="text-2xl font-bold">
            Stay tuned for the game-changing 2-way sync feature that ensures your data is 
            always synced both ways! 
          </h3>
          <h4 className="text-xl">🫣😮🤯</h4>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
