import { useEffect, useState } from "react";
import _ from "lodash";
import { GoogleSpreadsheet, GoogleSpreadsheetWorksheet } from "google-spreadsheet";

import GoogleSheetService from "../../services/GoogleSheetService";
import useUser from "./userHook";
import { IFile, ISheet } from "../../resources/types";
import Logger from "../../utilities/Logger";
import GoogleDriveService from "../../services/GoogleDriveService";
import useGoogleConnection from "../services/googleHook";
import { AxiosError } from "axios";
import { transformToUniqueList } from "../../utilities/sheetUtils";



const googleSheetService = new GoogleSheetService();
const googleDriveService = new GoogleDriveService();

function useSheetFile(){
  const {GoogleDetails} = useUser();
  const {refreshToken} = useGoogleConnection();
  const [selectedFile, setSelectedFile] = useState<IFile>();
  const [isReadonly, setIsReadonly] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [sheetDoc, setSheetDoc] = useState<GoogleSpreadsheet>();
  const [activeSheetTab, setActiveSheetTab] = useState(0);
  const SheetList: Array<ISheet> = _.range(sheetDoc?.sheetCount??0)
    .map<ISheet>((index) => ({
      id: sheetDoc?.sheetsByIndex[index].sheetId as number,
      title: sheetDoc?.sheetsByIndex[index].title as string,
    }));
  const Sheet = sheetDoc?.sheetsByIndex[activeSheetTab];

  useEffect(()=> {
    getUserPermissionList()
  }, [sheetDoc])

  //this handles updating the file name of a google sheet
  const updateSheetFileName = async (title: string) => {
    try {
      await sheetDoc?.updateProperties({
        title: title
      })

    } catch (error) {
      Logger.error(error);
    }
    
  }

  //this handles fetching the googlesheet file details (sheet data) by fileId
  const fetchFile = async (fileId: string, token?: string) => {
    try {
      setLoading(true);
      const sheetResponse = await googleSheetService.getSheetData(fileId, token || GoogleDetails.token as string);
      setSheetDoc(() => sheetResponse.doc);
      setActiveSheetTab(0);

    } catch (error) {
      Logger.error(error);

    } finally {
      setLoading(false);
    }
  }

  //this handles fetching the googlesheet file details (sheet data) by selectedFile
  const getSheetFileDetails = async (token?: string, gen = 0) =>{
    try {
      if(sheetDoc && selectedFile && sheetDoc.spreadsheetId) {
        const result = await googleDriveService.listFiles(token || GoogleDetails.token)

        if(result.length) {
          const file = result.find(f => f.id == sheetDoc.spreadsheetId);

          if(file) {
            setSelectedFile({
              ...selectedFile,
              ...file
            })
          }
        }
      }
      
    } catch (error: AxiosError | Error | any) {
      try {        
        if((error?.status || error?.response?.status) == 401 && gen <= 2) { // Refresh token
          const access_token = await refreshToken();
          await getSheetFileDetails(access_token, gen + 1);
        }
        
      } catch (error) {
        Logger.error(error);
        throw error;
      }
    }
  }

  const onSelectFile = async (file?: IFile) => {
    setSelectedFile(()=> file);

    if(file){
      await fetchFile(file.id);
    } 
      
  }

  const changeTabHandler = (tabIndex: number) => {
    setActiveSheetTab(tabIndex);
  }

  //this handles fetching the sheetData from source again
  const reloadSheetsFromSource = async (doc: GoogleSpreadsheet) => {
    await doc.loadInfo();
    getSheetFileDetails();
  }

  //this gets the permission of the user on the selected googlesheet
  const getUserPermissionList = async () => {
    try {
      await sheetDoc?.loadInfo()
      const usersList = await sheetDoc?.listPermissions()
      //@ts-ignore
      const getUserRole = usersList?.filter(e => e['emailAddress'] == GoogleDetails.email)
      if(getUserRole){
        if(getUserRole[0]?.role === 'commenter' || getUserRole[0]?.role === 'reader'){
          setIsReadonly(true)
        }else{
          setIsReadonly(false)
        }
      }
    } catch (error) {
      setIsReadonly(true)
      Logger.error(error);
    }
    
  }

  //this handles syncing data from the source to what we have in monday context. (If someone else updated the sheet outside of monday context)
  const handleDataSync = async (sheet: GoogleSpreadsheetWorksheet, gen = 0) => {
    try {    
      if(sheetDoc) {
        await sheetDoc.loadInfo();
        setSheetDoc(sheetDoc);
        reloadSheetsFromSource(sheetDoc);
        if(selectedFile?.id){
          fetchFile(selectedFile?.id)
        }
        
      }
      
      await sheet.saveUpdatedCells();
      await sheet.loadCells();
      await sheet.loadHeaderRow();

    } catch (error: AxiosError | Error | any) {
      try {        
        if((error?.status || error?.response?.status) == 401 && gen <= 2) { // Refresh token
          const access_token = await refreshToken();
          if(sheetDoc && GoogleDetails.token) {
            sheetDoc.auth = {
              token: access_token || GoogleDetails.token
            }

            sheet._spreadsheet.auth = {
              token: access_token || GoogleDetails.token
            }

            await handleDataSync(sheet, gen + 1);
            return;
          }
        }

        throw error;
        
      } catch (error) {
        Logger.error(error);
        throw error;
      }
    }
  }

  //this handles creating a new sheet in a googlespreadsheet
  const createNewSheet = async (doc: GoogleSpreadsheet, gen = 0) => {
    try {
      await doc.loadInfo();
      const sheetsCount = doc.sheetCount + 1;
      
      for(let i = 1; i <= sheetsCount; i++) {
        const sheetName = `Sheet${i}`;

        if(!doc.sheetsByTitle[sheetName]) {
          const newSheet = await doc.addSheet({
            title: sheetName,
            headerValues: _.range(0, 3).map((n) => ``)
          });

          setSheetDoc(doc);
          await reloadSheetsFromSource(doc);
          setActiveSheetTab(newSheet.index);
          break;
        }
      }
      
    } catch (error: AxiosError | Error | any) {
      try {   
        if((error?.status || error?.response?.status) == 401 && gen <= 2) { // Refresh token
          const access_token = await refreshToken();
          if(doc) {
            doc.auth = {
              token: (access_token || GoogleDetails.token) as string,
            }            

            await createNewSheet(doc, gen + 1);
            return;
          }
        }

        throw error;
        
      } catch (error) {
        Logger.error(error);
        throw error;
      }
    }
  }

  //updating sheet properties e.g the title of the sheet tab
  const updateSheet = async (sheetId: number, data: {title?: string}, doc?: GoogleSpreadsheet, gen = 0) => {
    try {
      if(sheetDoc || doc) {    
        const _doc =  doc ?? sheetDoc;
        await _doc?.sheetsById[sheetId].updateProperties({
          title: data.title
        });
        setSheetDoc(_doc);
      }
      
    } catch (error: AxiosError | Error | any) {
      try {        
        if((error?.status || error?.response?.status) == 401 && gen <= 2) { // Refresh token
          const access_token = await refreshToken();
          if(sheetDoc && GoogleDetails.token) {
            sheetDoc.auth = {
              token: access_token || GoogleDetails.token
            }

            await updateSheet(sheetId, data, sheetDoc, gen + 1);
            return;
          }
        }

        throw error;
        
      } catch (error) {
        Logger.error(error);
      }
    }
  }

  //this handles deleting a sheet from a spreadsheet
  const deleteSheet = async (sheetId: number, doc?: GoogleSpreadsheet, gen = 0) => {
    try {
      const _doc = doc ?? sheetDoc;
      if(_doc) {
        const currentSheetCount = _doc.sheetCount;

        if(_doc.sheetsById[sheetId]) {
          await _doc.deleteSheet(sheetId);
          setSheetDoc(_doc);

          if(activeSheetTab == currentSheetCount - 1) {
            const currentTab = (activeSheetTab - 1) < 0? 0 : (activeSheetTab - 1)
            setActiveSheetTab(currentTab);
          }
        }
      }

    } catch (error: AxiosError | Error | any) {
      try {        
        if((error?.status || error?.response?.status) == 401 && gen <= 2) { // Refresh token
          const access_token = await refreshToken();
          if(sheetDoc) {
            sheetDoc.auth = {
              token: (access_token || GoogleDetails.token) as string
            }

            await deleteSheet(sheetId, sheetDoc, gen + 1);
            return;
          }
        }

        throw error;
        
      } catch (error) {
        Logger.error(error);
      }
    }
  }

  //this handles creation of a new spreadsheet file
  const createNewSpreadsheet = async (fileName: string, file?: IFile, token?: string, gen = 0) => {
    try {
      setLoading(true);
      const doc = await googleSheetService.createSheetData(fileName, token || GoogleDetails.token as string);

      // To handle empty cells creations
      try {
        await doc.sheetsByIndex[0].setHeaderRow(_.range(0, 3).map((n) => ``));
        
      } catch (error) {
        Logger.error(error);
      }

      setSelectedFile({
        id: doc.spreadsheetId,
        name: doc.title,
        kind: "",
        createdTime: new Date().toISOString(),
        mimeType: "application/vnd.google-apps.spreadsheet",
        modifiedTime: new Date().toISOString(),
        path: file?.path
      });
      
      if(file?.id) {
        googleDriveService.updateFile(doc.spreadsheetId, file?.id, token || GoogleDetails.token as string)
      }

      fetchFile(doc.spreadsheetId);
    } catch (error: AxiosError | Error | any) {
      if((error?.status || error?.response?.status) == 401 && gen <= 2) { // Refresh token
        try {        
          const access_token = await refreshToken();
          if(access_token) {
            await createNewSpreadsheet(fileName, file, access_token, gen + 1);
          }
                      
        } catch (error) {
          Logger.error(error);
          throw error;
        }
      }
        
      Logger.error(error);
      throw error;
        
    } finally {
      setLoading(false);
    }
  }
    
  //this handles importing items on a selected board to the selected/imported sheet
  const importBoardToSheet = async (
    headers: Array<string>,
    rows: Array<string | number | boolean | Date>[],
    docSheet?: GoogleSpreadsheetWorksheet,
    gen = 0
  ) => {
    const _Sheet = docSheet ?? Sheet;

    try {
      setLoading(true);
      // Indicates that headers got transformed
      let hasTransformedHeaders = false;

      if(_Sheet) {
        let headerValues: string[] = [];

        /**
         * Checks on header values are made due to api not accepting a sheet with
         * no headers or empty headers.
         * Note: Empty string is not accepted as a header amid the use of "_"
         */
        // get the header values
        try {
          headerValues = _Sheet.headerValues;
        } catch (error) {
          headerValues = [];
        }
      
        // Transform duplicate headers by adding their occurence number to the title of header
        // Eg, Name -> Name1 or Name2
        const uniqueHeaders = transformToUniqueList(headers);

        // Create new headers if none exists
        if(_.isEmpty(headerValues)) {
          await _Sheet.setHeaderRow(uniqueHeaders);
          hasTransformedHeaders = true;

        } else {
          // Replace headers if only '_' (for empty sheet) exist
          if(headerValues.length == 1 && headerValues[0] == "_") {
            await _Sheet.setHeaderRow(uniqueHeaders);
            hasTransformedHeaders = true;

          } else { // Add new header to sheet
            await _Sheet.addRow(headers);
          }
        }

        await _Sheet.addRows(rows)
      }

      return hasTransformedHeaders;

    } catch (error: AxiosError | Error | any) {
      //try once more if the first one fails
      if((error?.status || error?.response?.status) == 401 && gen <= 2) { // Refresh token
        try {        
          const access_token = await refreshToken();

          if(access_token && _Sheet) {
            _Sheet._spreadsheet.auth = {
              token: access_token
            }

            await importBoardToSheet(headers, rows, _Sheet, gen + 1);
            return;
          }
             
        } catch (error) {
          Logger.error(error);
          throw error;
        }
      }

      Logger.error(error);
      throw error;

    } finally {
      setLoading(false);
    }
  }



  return {
    isLoading,
    sheetDoc,
    selectedFile,
    activeSheetTab,
    ActiveSheet: Sheet,
    SheetList,
    isReadonly,
    fetchFile,
    deleteSheet,
    updateSheet,
    createNewSpreadsheet,
    onSelectFile,
    changeTabHandler,
    handleDataSync,
    createNewSheet,
    importBoardToSheet,
    updateSheetFileName,
    getUserPermissionList
  }
}

export default useSheetFile;
