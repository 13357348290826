import mondaySdk from "monday-sdk-js";

import Logger from "../utilities/Logger";
import { MONDAY_CLIENT_ID } from "../configs";
import { IMondayQueryOptions } from "../resources/types/responses";
import { BaseContext } from "monday-sdk-js/types/client-context.type";



// const monday = mondaySdk();

class MondayService {
  private static monday  = mondaySdk();

  constructor() {
    MondayService.monday.setApiVersion("2024-04");
  }


  sendValueCreatedForUser() {
    MondayService.monday.execute("valueCreatedForUser");
  }

  openPlanSelection() {
    MondayService.monday.execute("openPlanSelection");
  }

  async getContext<T>() {
    try {
      const context = await MondayService.monday.get<BaseContext & T>("context");

      if(context.errorMessage) {
        throw new Error(context.errorMessage);
      }

      return context.data;
      
    } catch (error: Error | unknown) {
      Logger.error(error);
      throw error;
    }
  }

  async authorizeUser() {
    try {
      MondayService.monday.oauth({
        clientId: MONDAY_CLIENT_ID,
        mondayOauthUrl: "https://auth.monday.com/oauth2/authorize"
      });
      // const response = await monday.execute("openLinkInTab", {
      //   url: `https://auth.monday.com/oauth2/authorize?client_id=${MONDAY_CLIENT_ID}`,
      // });
      
    } catch (error) {
      Logger.error(error);
      throw error;
    }
  }

  async query<T>(qry: string, option?: IMondayQueryOptions) {
		try {

			const response = await MondayService.monday.api<T>(qry, {
				apiVersion: option?.apiVersion,
			});

			return response;
		} catch (error) {
			Logger.error(error);
			throw error;
		}
	}

}

export default MondayService;
